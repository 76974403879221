import { render, staticRenderFns } from "./exportTask.vue?vue&type=template&id=3b8b2b60&scoped=true&"
import script from "./exportTask.vue?vue&type=script&lang=js&"
export * from "./exportTask.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8b2b60",
  null
  
)

export default component.exports