<template>
  <publicExport :exportTypeList="exportTypeList"></publicExport>
</template>
<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import publicExport from "@/components/common/publicExport";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "exportTask",
  data() {
    return {
      exportTypeList: [
        {value: 'productExport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000672')},
        {value: 'inventoryExport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000701')},
        {value: 'inventoryLogExport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005533')},
        {value: 'packageExport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1002463')},
        {value: 'ymsInventoryExport', name: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1005532')}`},
        {value: 'supplierBillExport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005534')},
        {value: 'wmsPickupOrderExport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005535')},
        {value: 'packageInfoOrderExport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001327')},
        {value: 'packageInfoStockExport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1001224')},
      ]
    };
  },
  components: {
    publicExport
  }
};
</script>

<style scoped>

</style>
